<template>
  <ul class="coupon_list-ul">
    <li class="li">
      <div class="wrap">
        <!-- <div class="price">
            <div class="couponPrice">
              <span>￥</span>
              <b>10</b>
            </div>
            <p class="couponSub">满100元可用</p>
          </div> -->
        <div class="icon">
          <svg-icon icon-class="card_couse"></svg-icon>
        </div>
        <div class="title">
          <div class="t">
            <span>满减券</span>
            <h3>全场通用券</h3>
          </div>
          <p class="time">有效期至：2021.11.13</p>
          <p class="time">仅限于:限品</p>
        </div>
        <div class="solid">
          <img class="line" src="@image/me/1634288786.png" />
        </div>
        <div class="btn btn_act">
          <div>已使用</div>
        </div>
      </div>
    </li>
    <li class="li">
      <div class="wrap">
        <img src="" alt="" />
        <div class="price">
          <div class="couponPrice">
            <span>￥</span>
            <b>10</b>
          </div>
          <p class="couponSub">满100元可用</p>
        </div>
        <div class="title">
          <div class="t">
            <span>满减券</span>
            <h3>全场通用券</h3>
          </div>
          <p class="time">有效期至：2021.11.13</p>
        </div>
        <div class="solid">
          <img class="line" src="@image/me/1634288786.png" />
        </div>
        <div class="btn">
          <div>使用</div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.coupon_list-ul {
  .li {
    padding: 0 rem(32);
    background: #fdeef1;
    border: 1px solid #f2dce0;
    box-sizing: border-box;
    border-radius: rem(16);
    width: rem(690);
    margin: 0 auto rem(30);
    height: rem(148);
    display: flex;
  }
  .wrap {
    display: flex;
    width: 100%;
    align-items: center;
    .icon {
      width: rem(128);

      margin-right: rem(46);
      svg {
        margin: 0 auto;
        display: block;
        width: rem(80);
        height: rem(80);
      }
    }
    .price {
      margin-bottom: rem(16);
      width: rem(150);
      margin-right: rem(24);
      color: #ff4848;
      .couponPrice {
        height: rem(70);
        text-align: center;
        span {
          font-size: rem(28);
        }
        b {
          font-weight: bold;
          font-size: rem(50);
        }
      }
      .couponSub {
        font-size: rem(22);
        @include ell;
      }
    }
    .title {
      margin-bottom: rem(16);
      flex: 1;
      .t {
        display: flex;
        align-items: center;
        height: rem(70);

        span {
          display: block;
          color: #ffffff;
          font-size: rem(20);
          height: rem(28);
          line-height: rem(28);
          padding: 0 rem(6);
          text-align: center;
          background: #fc7474;
          border-radius: rem(6);
          margin-right: rem(6);
        }
        h3 {
          color: #222222;
          font-weight: bold;
          font-size: rem(28);
          @include ell;
        }
      }
      .time {
        color: #666666;
        font-size: rem(22);
      }
    }
    .solid {
      position: relative;
      width: rem(22);
      height: rem(148);
      .line {
        position: absolute;
        width: rem(22);
        height: rem(148);
        top: rem(-0);
        left: 0;
      }
    }

    .btn {
      width: rem(130);
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        background: #ffffff;
        width: rem(74);
        height: rem(44);
        border: 1px solid #ff4848;
        box-sizing: border-box;
        border-radius: rem(6);
        width: rem(74);
        height: rem(44);
        line-height: rem(44);
        text-align: center;
        color: #ff4848;
        font-size: rem(26);
        margin-left: rem(24);
      }
    }
    .btn_act {
      div {
        width: rem(100);
        color: #f2dce0;
        border: 1px solid #f2dce0;
        margin-left: rem(30);
      }
    }
  }
}
</style>
